<template>
    <el-dialog :model-value="show" :title="dish.name" width="95%" top="5vh" :close-on-click-modal="false" @close="handleClose" @opened="handleOpened">
        <div class="details_dialog_content" v-loading="loading" element-loading-background="rgba(255,255,255,.4)">
            <div class="chart">
                <div class="dish_chart_box" id="dish_chart_box"></div>
                <div class="dish_chart_bar_box" id="dish_chart_bar_box"></div>
                <div class="right_box">
                    <div class="top_box" id="top_brand_chart"></div>
                    <div class="top_box" id="top_shop_chart"></div>
                </div>
                <div class="right_box">
                    <div class="top_box" id="top_brand_chart_all"></div>
                    <div class="top_box" id="top_shop_chart_all"></div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, computed } from "vue";
import useLineChart from "./useLineChart";
import useBarChart from "./useBarChart";
import useTopChart from "./useTopChart";

const route = useRoute();
const props = defineProps({
    show: Boolean,
    dish: { type: Object, default: () => {} },
    chartData: { type: Object, default: () => {} },
    handleDownload: Function,
});
const emits = defineEmits(["close"]);
// 维度名称
const itemNames = computed(() => {
    const items = props.dish?.name?.split("+");
    if (!items?.length) return {};
    const res = {
        itemName: items[0],
    };
    if (items[1]) {
        res["itemName2"] = items[1];
    }
    return res;
});
const batch = ref(""); // 折线图选中的batch
const dishName = ref(""); // 中间柱状图选中的菜品
const loading = computed(() => {
    const loadingList = [leLoading.value, barLoading.value, brandLoading.value, shopLoading.value, brandAllLoading.value, shopAllLoading.value];
    return loadingList.some((item) => item);
});

// 折线图
const { leLoading, getData, handleLineDispose } = useLineChart({ props, itemNames, route, batch });

// 中间柱状图
const { barLoading, getTopData, handleDispose } = useBarChart({ props, itemNames, route, batch, dishName, iniTop3: () => iniTop3() });

// 右侧柱状图-top3
const { brandLoading, shopLoading, brandAllLoading, shopAllLoading, handleTopDispose, iniTop3 } = useTopChart({ props, itemNames, route, batch, dishName });

// 打开弹窗
function handleOpened() {
    getData();
    getTopData();
}

function handleClose() {
    handleLineDispose(); // 销毁折线图
    handleDispose(); // 销毁中间柱状图
    handleTopDispose(); // 销毁右侧柱状图
    batch.value = ""; // 清空选中batch
    dishName.value = ""; // 清空选中dishName
    emits("close");
}
</script>

<style lang="scss" scoped>
.details_dialog_content {
    height: 600px;
    .chart {
        display: flex;
        height: 100%;
        .dish_chart_box {
            width: 30%;
            height: 100%;
        }
        .dish_chart_bar_box {
            width: 20%;
            height: 100%;
        }
        .right_box {
            width: 25%;
            height: 100%;
            .top_box {
                height: 50%;
            }
        }
    }
}
</style>
