import { ref } from 'vue';
import { apiEgpmTaskResultSingleStatsItem } from "@/request/egpmMaterialApi";
import { colors } from "@/config/egpm";
import echarts from "@/utils/echarts";

export default ({ props, itemNames, route, batch }) => {

    const leLoading = ref(false);
    const arrows = ref([]);// 箭头数据
    // markArea
    const markAreaMap = [
        { name: "涌现", color: "#e7e6e6" },
        { name: "增长", color: "#dae3e8" },
        { name: "流行", color: "#c3cfe1" },
        { name: "成熟", color: "#9db1cf" },
    ];
    let myChart = null; // 折线图

    // 获取数据
    async function getData() {
        leLoading.value = true;
        try {
            const params = { taskId: route.query.id, ...itemNames.value };
            const { data } = await apiEgpmTaskResultSingleStatsItem(params);
            arrows.value = data || [];
            initChart();
        } finally {
            leLoading.value = false;
        }
    }

    // 格式化曲线图数据
    function filterLineData(data = []) {
        return data.map((item) => {
            return {
                value: [item.x, item.y],
            };
        });
    }

    // 格式化箭头图数据
    function filterArrowData(data = []) {
        return data.map((item) => {
            return {
                name: item.batch,
                value: [item.x, item.y],
                itemStyle: {
                    color: colors[item.flag],
                },
            };
        });
    }

    // 参考线数据
    function filterMarkLineData(data = []) {
        const res = [];
        data.forEach((item) => {
            // Y轴
            if (item.name === "C1" || item.name === "C5") return;
            res.push({
                yAxis: item.y,
            });
            // X轴
            res.push({
                xAxis: item.x,
            });
        });
        return res;
    }

    function filterMarkAreaData(data = []) {
        let res = [];
        const xs = data.map((item) => {
            return item.x;
        });
        xs.forEach((item, index, arr) => {
            if (arr.length - 1 === index) return;
            res.push([{ xAxis: item, name: "", itemStyle: { color: "" } }, { xAxis: xs[index + 1] }]);
        });
        res = res.map((item, index) => {
            item[0].name = markAreaMap[index]?.name;
            item[0].itemStyle.color = markAreaMap[index]?.color;
            return item;
        });
        return res;
    }

    // resize
    function handleResize() {
        myChart && myChart.resize();
    }

    // 折线图
    function initChart() {
        const chartDom = document.getElementById("dish_chart_box");
        myChart = echarts.init(chartDom);
        const arrowArr = filterArrowData(arrows.value);
        const markLineData = [];
        arrowArr.forEach((item, index, arr) => {
            if (arrowArr.length - 1 === index) return;
            markLineData.push([{ coord: item.value }, { coord: arr[index + 1].value }]);
        });
        const option = {
            tooltip: {
                trigger: "item",
            },
            grid: {
                top: 30,
                left: 30,
                right: 30,
                bottom: 30,
                containLabel: true,
            },
            xAxis: {
                name: "供给指数",
                type: "value",
                splitLine: { show: false },
                nameLocation: "center",
                min: 0,
                max: 10,
            },
            yAxis: {
                name: "需求指数",
                type: "value",
                splitLine: { show: false },
                nameTextStyle: {
                    align: "right",
                },
                min: 0,
                max: 10,
            },
            series: [
                {
                    type: "line",
                    smooth: true,
                    lineStyle: {
                        color: "#773fa5",
                    },
                    symbolSize: 0,
                    data: filterLineData(props.chartData?.curve || []),
                    // 参考线
                    markLine: {
                        lineStyle: {
                            color: "#999",
                        },
                        symbolSize: 0,
                        label: {
                            show: false,
                        },
                        data: filterMarkLineData(props.chartData?.cross || []),
                        emphasis: {
                            disabled: true,
                        },
                        tooltip: {
                            show: false,
                        },
                    },
                },
                {
                    data: arrowArr,
                    type: "line",
                    lineStyle: {
                        color: "#b5bd48",
                    },
                    tooltip: {
                        show: true,
                        formatter: "{b}",
                    },
                    z: 10,
                    // 使用参考线覆盖一层，参考线自带箭头
                    markLine: {
                        animation: false,
                        emphasis: { disabled: true },
                        data: markLineData,
                        symbol: ["none", "arrow"],
                        lineStyle: {
                            color: "#b5bd48",
                            join: "miter",
                        },
                        tooltip: {
                            show: false,
                        },
                    },
                    markArea: {
                        data: filterMarkAreaData(props.chartData?.cross || []),
                        emphasis: {
                            disabled: true,
                        },
                        tooltip: {
                            show: false,
                        },
                    },
                },
            ],
        };
        // click
        myChart.on("click", (params) => {
            if (params.componentSubType !== "line") return;
            batch.value = params.name;
        });
        // resize
        window.addEventListener("resize", handleResize);

        option && myChart.setOption(option);
    }

    // 销毁
    function handleLineDispose() {
        window.removeEventListener("resize", handleResize);
        myChart && myChart.dispose();
        myChart = null;
        leLoading.value = false;
    }

    return { leLoading, getData, handleLineDispose }
}