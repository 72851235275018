import echarts from "@/utils/echarts";
import { onBeforeUnmount, ref, watch, nextTick } from "vue";
import { colors } from "@/config/egpm";
import { ElMessage } from 'element-plus';
import { unique } from '@/utils';

export default ({ open, showExportScreen, exportScreenList }) => {

    let items = [];// 散点数据暂存，用于区域选择查找数据
    let myChart = null; // 图表实例
    const scatterLabelShow = ref('N');// 是否显示散点图balel
    const isBrush = ref(false);// 区域选择开启
    let brushIndexs = [];// 区域选择选中数据的下标

    // 重载图表
    function reloadChart() {
        if (!myChart) return;
        const option = myChart.getOption();
        myChart.clear();
        myChart.setOption(option);
    }

    // 曲线图数据
    function filterLineData(data = []) {
        return data.map(item => {
            return {
                value: [item.x, item.y]
            }
        })
    }
    // 散点图数据
    function filterScatterData(data = []) {
        const res = data.map(item => {
            let itemStyle;
            if (item.flag.startsWith('N')) {
                itemStyle = {
                    color: '#fff',
                    borderColor: colors[item.flag],
                    borderWidth: 2
                }
            } else {
                itemStyle = {
                    color: colors[item.flag]
                }
            }
            let name = item.itemName;
            if (item.itemName2) {
                name += `+${item.itemName2}`
            }
            return {
                ...item,
                name,
                value: [item.x, item.y],
                itemStyle: itemStyle
            }
        })
        items = res;// 暂存散点数据，用于区域选择查找数据
        return res;
    }
    // 参考线数据
    function filterMarkLineData(data = []) {
        const res = [];
        data.forEach(item => {
            // Y轴
            if (item.name === 'C1' || item.name === 'C5') return
            res.push({
                yAxis: item.y,
            })
            // X轴
            res.push({
                xAxis: item.x,
            })
        })
        return res;
    }

    // markArea
    const markAreaMap = [
        { name: "涌现", color: "#e7e6e6" },
        { name: "增长", color: "#dae3e8" },
        { name: "流行", color: "#c3cfe1" },
        { name: "成熟", color: "#9db1cf" },
    ];
    function filterMarkAreaData(data = []) {
        let res = [];
        const xs = data.map((item) => {
            return item.x;
        });
        xs.forEach((item, index, arr) => {
            if (arr.length - 1 === index) return;
            res.push([{ xAxis: item, name: "", itemStyle: { color: "" } }, { xAxis: xs[index + 1] }]);
        });
        res = res.map((item, index) => {
            // item[0].name = markAreaMap[index]?.name;
            item[0].itemStyle.color = markAreaMap[index]?.color;
            return item;
        });
        return res;
    }

    // resize
    function handleResize() {
        myChart && myChart.resize();
    }

    // 渲染导出筛选选中的点
    function renderExportScreen() {
        if (!myChart) return;
        const options = myChart.getOption();
        options.series[0].data = options.series[0].data.map(item => {
            if (exportScreenList.value.some((i) => item.name === i.name)) {
                item.itemStyle.shadowBlur = 4;
                item.itemStyle.shadowColor = '#005ca9';
            } else {
                item.itemStyle.shadowBlur = 0;
            }
            return item;
        })
        nextTick(() => {
            myChart.setOption(options);
        })
    }

    // 散点图点击事件
    function handleScatterClick(params) {
        // 没打开导出筛选，弹出单菜弹框
        if (!showExportScreen.value) {
            open(params.data);
            return;
        }
        // 打开了导出筛选，将点击的点添加到导出筛选列表中
        if (exportScreenList.value.some((item) => item.name === params.data.name)) return;
        if (!params.data.name) return;
        exportScreenList.value.unshift(params.data);
        renderExportScreen();
    }

    // 区域选择开启
    function handleAreaSelect() {
        isBrush.value = true;
        myChart.dispatchAction({
            type: 'takeGlobalCursor',
            // 如果想变为“可刷选状态”，必须设置。不设置则会关闭“可刷选状态”。
            key: 'brush',
            brushOption: {}
        })
    }

    // 区域选择关闭
    function handleAreaSelectClose() {
        if (!isBrush.value) return;
        isBrush.value = false;
        brushIndexs = [];
        // 关闭刷选状态
        myChart.dispatchAction({
            type: 'takeGlobalCursor',
        })
        reloadChart();// 重新渲染，移除选框
    }

    // 区域选择确认
    function handleAreaSelectConfirm() {
        if (!brushIndexs.length) {
            ElMessage.error('选区中没有数据');
            return;
        }
        const res = [];
        brushIndexs.forEach(item => {
            res.push(items[item]);
        })
        exportScreenList.value = unique([...res, ...exportScreenList.value], 'name');
        handleAreaSelectClose();// 关闭刷选状态
        renderExportScreen();// 渲染选中的点
    }

    // 初始化图表
    function initChart(data = []) {
        if (myChart) {
            myChart.dispose();
            myChart = null;
        }
        const chartDom = document.getElementById("chart_box");
        myChart = echarts.init(chartDom);

        const option = {
            animation: false,
            tooltip: {
                show: true,
                trigger: "item",
                formatter: (params) => {
                    return params.name;
                }
            },
            grid: {
                top: 30,
                left: 30,
                right: 30,
                bottom: 30,
                containLabel: true,
            },
            toolbox: { show: false },
            brush: {
                toolbox: ['rect', 'clear'],
                seriesIndex: [0],// 刷选框只对第1个series生效(散点图)
                transformable: true,// 已经选好的选框是否可以被调整形状或平移。
                throttleType: 'debounce',// brushSelected事件触发频率，debounce停止了才会触发
                // 刷选框样式
                brushStyle: {
                    color: 'rgba(51,125,186,0.2)', // 图形颜色
                    borderColor: '#005CA9', // 图形描边颜色
                    borderWidth: 2, // 图形描边宽度
                    borderType: 'solid', // 图形描边线类型
                },
                // 选框中的视觉元素样式 
                inBrush: {},
                // 未选中的视觉元素样式
                outOfBrush: { colorAlpha: 1 },
            },
            xAxis: {
                name: "供给指数",
                nameLocation: "center",
                splitLine: { show: false },
                min: 0,
                max: 10
            },
            yAxis: {
                name: "需求指数",
                splitLine: { show: false },
                min: 0,
                max: 10
            },
            dataZoom: [
                {
                    type: 'inside',
                    xAxisIndex: 0,
                    filterMode: 'none',
                    throttle: 0,
                },
                {
                    type: 'inside',
                    yAxisIndex: 0,
                    filterMode: 'none',
                    throttle: 0,
                }
            ],
            series: [
                // 散点图
                {
                    type: "scatter",
                    symbolSize: 8,
                    zlevel: 3,
                    label: {
                        show: scatterLabelShow.value === 'Y',
                        formatter: (e) => e.name,
                        position: 'top',
                        padding: 8
                    },
                    labelLayout: {
                        hideOverlap: true,
                        moveOverlap: 'shiftX'
                    },
                    data: filterScatterData(data?.item || []),
                    // 参考线
                    markLine: {
                        lineStyle: {
                            color: "#999"
                        },
                        symbolSize: 0,
                        label: {
                            show: false
                        },
                        data: filterMarkLineData(data?.cross || []),
                        emphasis: {
                            disabled: true,
                        },
                    },
                },
                // 折线图
                {
                    type: "line",
                    smooth: true,
                    lineStyle: {
                        color: '#773fa5'
                    },
                    symbolSize: 0,
                    data: filterLineData(data?.curve || []),
                    markArea: {
                        data: filterMarkAreaData(data?.cross || []),
                        emphasis: {
                            disabled: true,
                        },
                    },
                },
            ],
        };
        // 注册散点图点击事件
        myChart.on('click', 'series.scatter', handleScatterClick);

        // 注册缩放事件
        myChart.on('datazoom', (e) => {
            const zoom = e.batch[0].end - e.batch[0].start;
            if (zoom < 11 || scatterLabelShow.value === 'Y') {
                option.series[0].label.show = true;
                myChart.setOption(option);
            } else {
                option.series[0].label.show = false;
                myChart.setOption(option);
            }
            renderExportScreen();
        });

        // 区域选择事件
        myChart.on("brushSelected", (e) => {
            brushIndexs = e.batch[0].selected[0].dataIndex;
        });

        myChart.setOption(option);
        window.addEventListener("resize", handleResize);
    }

    watch(() => scatterLabelShow.value, (val) => {
        if (!myChart) return;
        const options = myChart.getOption();
        options.series[0].label.show = val === 'Y' ? true : false;
        myChart.setOption(options);
    })

    onBeforeUnmount(() => {
        window.removeEventListener("resize", handleResize);
        myChart = null;
    });

    return { handleResize, initChart, scatterLabelShow, renderExportScreen, handleAreaSelect, isBrush, handleAreaSelectClose, handleAreaSelectConfirm, handleAreaSelectClose }
}